import React, {useEffect, useState} from "react";
import {
  db,
  collection,
  getDocs,
  query,
  limit,
} from "../../config/firebaseConfig";

interface Document {
  id: string;
  title: string;
  description: string;
  image: string;
}

interface Props {
  qty?: number;
}

const Projects: React.FC<Props> = ({qty}) => {
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const querySnapshot = await getDocs(
          qty ?
          query(collection(db, "projects"), limit(qty)) : query(collection(db, "projects"))
        );
        const fetchedDocuments: Document[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Document[];
        setDocuments(fetchedDocuments);
      } catch (error) {
        console.error("Erro ao buscar documentos:", error);
      } finally {
      }
    };

    fetchDocuments();
  }, []);

  return (
    <div className="container-xxl py-5 pt-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{maxWidth: "600px"}}>
          <h6 className="text-primary">Projetos</h6>
          <h1 className="mb-4">
            Nossos mais recentes projetos de energia solar
          </h1>
        </div>
        <div
          className="row g-4 portfolio-container wow fadeInUp"
          data-wow-delay="0.5s">
          {documents.map((doc) => (
            <div className="col-lg-4 col-md-6 portfolio-item first">
              <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src={doc.image} alt="" />
                <div className="portfolio-btn">
                  <a
                    className="btn btn-lg-square btn-outline-light rounded-circle mx-1"
                    href={doc.image}
                    data-lightbox="portfolio">
                    <i className="fa fa-eye"></i>
                  </a>
                </div>
              </div>
              <div className="pt-3">
                <p className="text-primary mb-0">{doc.title}</p>
                <hr className="text-primary w-25 my-2" />
                <h5 className="lh-base">{doc.description}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
