// src/components/TeamMember.tsx
import React from 'react';

interface TeamMemberProps {
  name: string;
  role: string;
  imgSrc: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ name, role, imgSrc }) => {
  return (
    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div className="team-item rounded overflow-hidden">
        <div className="d-flex">
          <img className="img-fluid w-75" src={imgSrc} alt={name} />
        </div>
        <div className="p-4">
          <h5>{name}</h5>
          <span>{role}</span>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
