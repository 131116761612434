import React from "react";
import "../../App.css";

const Navbar: React.FC = () => {
  const handleAboutUs = () => {
    window.location.href = "/#aboutus";
  };

  const handleContacts = () => {
    window.location.href = "/#contacts";
  };

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
      <a
        href="/"
        className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5">
        <img
          className="img-fluid"
          width="100"
          height="100"
          src="img/logo.svg"
          alt=""
        />
      </a>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <a href="/" className="nav-item nav-link active">
            Início
          </a>
          <a onClick={handleAboutUs} className="nav-item nav-link">
            Sobre Nós
          </a>
          <a href="project" className="nav-item nav-link">
            Projetos
          </a>
          <a onClick={handleContacts} className="nav-item nav-link">
            Contatos
          </a>
        </div>
        <a
          href="https://instagram.com/unioengenhariaenergy?igshid=NGVhN2U2NjQ0Yg=="
          className="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">
          Instagram
          <i className="fa fa-arrow-right ms-3"></i>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
