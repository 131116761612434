import React, { useEffect, useRef, useState } from "react";
import { db, collection, getDocs } from "../../config/firebaseConfig";

const Carousel: React.FC = () => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");

  const updateImgSize = () => {
    if (imgRef.current) {
      const width = imgRef.current.width;
      const height = imgRef.current.height;
      setImgSize({ width, height });
    }
  };

  useEffect(() => {
    updateImgSize();
    window.addEventListener("resize", updateImgSize);
    return () => {
      window.removeEventListener("resize", updateImgSize);
    };
  }, [imgRef]);

  useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.style.width = `${imgSize.width}px`;
      overlayRef.current.style.height = `${imgSize.height}px`;
    }
  }, [imgSize]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "top"));
        const items = querySnapshot.docs.map((doc) => doc.data());
        if (items.length > 0) {
          setDescription(items[0].description);
          setTitle(items[0].title);
        }
      } catch (error) {
        console.error("Error fetching carousel data:", error);
      }
    };

    fetchData();

    return () => {
      window.removeEventListener("resize", updateImgSize);
    };
  }, []);

  return (
    <>
      <div className="hero">
        <div className="hero-overlay" ref={overlayRef}>
          <div className="overlay-content">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
        <img
          ref={imgRef}
          src="img/topo.jpg"
          alt="bubbles from surf"
          className="hero-image"
        />
      </div>
    </>
  );
};

export default Carousel;
