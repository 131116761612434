import React from "react";
import About from "../components/home/About";
import Carousel from "../components/home/Carousel";
import Feature from "../components/home/Feature";
import Projects from "../components/common/Projects";
import Quote from "../components/home/Quote";
import Team from "../components/home/TeamSection";
import Testimonials from "../components/home/Testimonials";
import FeaturePoints from "../components/home/FeaturePoints";
import {scroller} from "react-scroll";

const Home: React.FC = () => {
  const handleLoad = () => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      scroller.scrollTo(hash, {
        duration: 500,
        delay: 100,
        smooth: true,
      });
    }
  };

  return (
    <div className="App" onLoad={handleLoad}>
      <Carousel />
      <FeaturePoints />
      <About />
      <Testimonials />
      <Feature />
      <Projects qty={6} />
      <Quote />
      <Team />
    </div>
  );
};

export default Home;
