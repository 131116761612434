import React, { useEffect, useState } from 'react';
import { db, collection, getDocs } from '../../config/firebaseConfig';

const Quote: React.FC = () => {
  const [description, setDescription] = useState<string | undefined>("");
  const [title, setTitle] = useState<string | undefined>("");
  const [image, setImagem] = useState<string>("");
  const [service, setService] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'quote'));
        const items = querySnapshot.docs.map((doc) => doc.data());
        if (items && items.length > 0) {
          setDescription(items[0].description);
          setTitle(items[0].title);
          setImagem(items[0].image);
        }
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const name = (document.getElementById('name') as HTMLInputElement)?.value;
    const email = (document.getElementById('email') as HTMLInputElement)?.value;
    const phone = (document.getElementById('phone') as HTMLInputElement)?.value;
    const message = (document.getElementById('message') as HTMLTextAreaElement)?.value;

    const whatsappMessage = `Olá, meu nome é ${name}.\n\n${message}`;

    window.location.href = `https://wa.me/5579988169377?text=${encodeURIComponent(whatsappMessage)}`;
  };

  return (
    <div id="contacts" className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container quote px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
            <div className="position-relative h-100">
              <img className="position-absolute img-fluid w-100 h-100" src={image} style={{ objectFit: 'cover' }} alt="" />
            </div>
          </div>
          <div className="col-lg-6 quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
            <div className="p-lg-5 pe-lg-0">
              <h6 className="text-primary">Orçamento Gratuito</h6>
              <h1 className="mb-4">{title}</h1>
              <p className="mb-4 pb-2">{description}</p>
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-12 col-sm-12">
                    <input type="text" className="form-control border-0" placeholder="Nome" id="name" style={{ height: '55px' }} />
                  </div>
                  <div className="col-12">
                    <textarea className="form-control border-0" rows={6} placeholder="Mensagem" id="message"></textarea>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Enviar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
