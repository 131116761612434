import React from "react";

const Header: React.FC = () => {
  return (
    <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">Projetos</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item">Páginas</li>
                    <li className="breadcrumb-item text-white active" aria-current="page">Projetos</li>
                </ol>
            </nav>
        </div>
    </div>
  );
};

export default Header;
