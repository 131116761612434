import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Firestore, getFirestore, collection, addDoc, getDocs, doc, query, orderBy, limit, updateDoc, deleteDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB0L8DpBwkLsFUGpzdqP4WCLeS18x8t6fo",
  authDomain: "unioenergy-a4f2c.firebaseapp.com",
  projectId: "unioenergy-a4f2c",
  storageBucket: "unioenergy-a4f2c.appspot.com",
  messagingSenderId: "938828020969",
  appId: "1:938828020969:web:8621eab9af0f27c9e54478",
  measurementId: "G-YHEQLHP8FK"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const db: Firestore = getFirestore(app);

export { auth, db, collection, addDoc, getDocs, doc, query, orderBy, limit, updateDoc, deleteDoc, googleAuthProvider, signInWithPopup };