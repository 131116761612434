import React, {useEffect, useState} from "react";
import {
  db,
  collection,
  getDocs,
  query,
  limit,
} from "../../config/firebaseConfig";
import TeamMember from "./TeamMember";

interface Document {
  id: string;
  title: string;
  description: string;
  image: string;
}

const TeamSection: React.FC = () => {
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "team"), limit(3))
        );
        const fetchedDocuments: Document[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Document[];
        setDocuments(fetchedDocuments);
      } catch (error) {
        console.error("Erro ao buscar documentos:", error);
      } finally {
      }
    };

    fetchDocuments();
  }, []);

  return (
    <section className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{maxWidth: "600px"}}>
          <h6 className="text-primary">Membros da Equipe</h6>
          <h1 className="mb-4">Equipe Experiente</h1>
        </div>
        <div className="row g-4">
          {documents.map((doc) => (
            <TeamMember
              key={doc.id}
              name={doc.title}
              role={doc.description}
              imgSrc={doc.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
