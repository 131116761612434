import React, {useState, useEffect} from "react";
import {
  db,
  collection,
  getDocs,
  doc,
  query,
  limit,
  updateDoc,
} from "../../config/firebaseConfig";

const CarouselForm: React.FC = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [firstDocumentId, setFirstDocumentId] = useState<string | null>(null);

  useEffect(() => {
    const fetchFirstDocumentId = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "top"), limit(1))
        );
        const firstDocument = querySnapshot.docs[0];
        setFirstDocumentId(firstDocument?.id || null);
        setTitle(firstDocument?.data()?.title || "");
        setDescription(firstDocument?.data()?.description || "");
      } catch (error) {
        console.error("Erro ao buscar o primeiro documento:", error);
      }
    };

    fetchFirstDocumentId();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      if (firstDocumentId) {
        await updateDoc(doc(db, "top", firstDocumentId), {
          title: title,
          description: description,
          timestamp: new Date(),
        });
        console.log("Informações do carrossel atualizadas com sucesso!");
      } else {
        console.error("Nenhum documento encontrado para atualizar.");
      }
    } catch (error) {
      console.error("Erro ao enviar informações do carrossel:", error);
    }
  };

  return (
    <div className="row g-3">
      <div className="col-md-12">
        <label className="form-label">Titulo</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleFormControlTextarea1" className="form-label">
          Descrição
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="form-control"
          rows={5}></textarea>
      </div>
      <div className="col-12 d-flex justify-content-end">
        <button onClick={handleSubmit} className="btn btn-primary">
          Salvar
        </button>
      </div>
    </div>
  );
};

export default CarouselForm;
