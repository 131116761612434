import React, {useEffect, useState} from "react";
import {db, collection, getDocs, query} from "../../config/firebaseConfig";

interface Document {
  id: string;
  title: string;
  description: string;
  image: string;
}

const Testimonials: React.FC = () => {
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "testimonial"))
        );
        const fetchedDocuments: Document[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Document[];
        setDocuments(fetchedDocuments);
      } catch (error) {
        console.error("Erro ao buscar documentos:", error);
      } finally {
      }
    };

    fetchDocuments();
  }, []);

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center mx-auto mb-5" style={{maxWidth: 600}}>
          <h6 className="text-primary">Depoimentos</h6>
          <h1 className="mb-4">O que nossos clientes dizem!</h1>
        </div>

        <div
          id="testimonialCarousel"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel">
          <div className="carousel-inner">
            {documents.map((doc) => (
              <div className="carousel-item active">
                <div className="testimonial-item column text-center">
                  <div className="col">
                    <div className="testimonial-img position-relative">
                      <img
                        className=" rounded-circle mx-auto mb-2"
                        src={doc.image == "" ? "img/clientes.png" : doc.image}
                        alt="Client Avatar"
                        width="100"
                        height="100"
                      />
                      <div
                        className="btn-square bg-primary rounded-circle position-absolute top-0 start-50 translate-middle"
                        style={{marginTop: "97px"}}>
                        <i className="fa fa-quote-left text-white"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-8 mx-auto">
                    <div className="testimonial-text text-center rounded p-4">
                      <p>{doc.description}</p>
                      <h5 className="mb-1">{doc.title}</h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide="prev">
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"></span>
            <span className="visually-hidden">Anterior</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide="next">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"></span>
            <span className="visually-hidden">Próximo</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
