import React, { useEffect, useState } from 'react';
import { db, collection, getDocs } from '../../config/firebaseConfig';

const About: React.FC = () => {
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [image, setImagem] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'aboutus'));
        const items = querySnapshot.docs.map((doc) => doc.data());
        if(items){
          setDescription(items[0].description);
          setTitle(items[0].title);
          setImagem(items[0].image)
        }
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      }
    };

    fetchData();
  }, []);
  
  return (
    <div id="aboutus" className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container about px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 ps-lg-0 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
            <div className="position-relative h-100">
              <img className="position-absolute img-fluid w-100 h-100" src={image} style={{ objectFit: 'cover' }} alt="" />
            </div>
          </div>
          <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
            <div className="p-lg-5 pe-lg-0">
              <h6 className="text-primary">Sobre Nós</h6>
              <h1 className="mb-4">
                {title == '' ? `Com mais de anos de dedicação e experiência na indústria de energia solar e renovável` : title}
              </h1>
              <p>
                {description == '' ? `Nossa jornada é marcada por uma paixão incessante por inovação e sustentabilidade. Ao longo dos anos, desenvolvemos um profundo entendimento do potencial transformador da energia solar.
                Acreditamos que era hora de romper com as barreiras convencionais, assim como era hora de se livrar das vinhas que limitavam o crescimento. Nosso compromisso com a excelência levou-nos a desafiar o status quo e a buscar soluções que vão além do convencional.
                Ao longo dessa jornada, encontramos inspiração na natureza divina da energia solar, muitas vezes comparada à divindade. Cada projeto que abordamos é uma expressão de nossa devoção à criação de soluções eficazes e sustentáveis.` : description}
              </p>
              <p>
                <i className="fa fa-check-circle text-primary me-3"></i>Compromisso com a Inovação Sustentável:
              </p>
              <p>
                <i className="fa fa-check-circle text-primary me-3"></i>Conexão entre Visão e Realidade
              </p>
              <p>
                <i className="fa fa-check-circle text-primary me-3"></i>Técnico e Visionário, Juntos na Jornada
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
