import React, { useState, useEffect, ChangeEvent } from "react";
import {
  db,
  collection,
  getDocs,
  doc,
  query,
  limit,
  updateDoc,
} from "../../config/firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FooterForm: React.FC = () => {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstDocumentId, setFirstDocumentId] = useState<string | null>(null);

  useEffect(() => {
    const fetchFirstDocumentId = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "footer"), limit(1))
        );
        const firstDocument = querySnapshot.docs[0];
        setFirstDocumentId(firstDocument?.id || null);
        setAddress(firstDocument?.data()?.address || "");
        setPhone(firstDocument?.data()?.phone || "");
        setEmail(firstDocument?.data()?.email || "");
      } catch (error) {
        console.error("Erro ao buscar o primeiro documento:", error);
      }
    };

    fetchFirstDocumentId();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (firstDocumentId) {
        await updateDoc(doc(db, "footer", firstDocumentId), {
          address: address,
          phone: phone,
          email: email,
          timestamp: new Date(),
        });

        toast.success("Informações atualizadas com sucesso!");
      } else {
        console.error("Nenhum documento encontrado para atualizar.");
      }
    } catch (error) {
      toast.error("Erro ao enviar informações. Tente novamente.");
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-12">
          <label className="form-label">Endereço Completo</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-12">
          <label className="form-label">Telefone</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            E-mail
          </label>
          <input
          type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control" />
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button onClick={handleSubmit} className="btn btn-primary">
            Salvar
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default FooterForm;
