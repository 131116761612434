// src/App.tsx
import React from 'react';
import Projects from '../components/common/Projects';
import Header from '../components/common/Header';

const Project: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Projects />
    </div>
  );
};

export default Project;
