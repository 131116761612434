import React, { useState } from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Footer from "./components/common/Footer";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Project from "./pages/Project";
import Navbar from "./components/common/Navbar";
import Spinner from "./components/common/Spinner";

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const handleLoad = async () => {
    await sleep(2000);
    setLoading(false)
  };

  const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  return (
    <Router>
              <Spinner loading={loading} />
      <div className="App" onLoad={handleLoad}>

        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/project" element={<Project />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
