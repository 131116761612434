import React, { useEffect, useState } from "react";
import { db, collection, getDocs } from '../../config/firebaseConfig';

const Footer: React.FC = () => {
  const [address, setAddress] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'footer'));
        const items = querySnapshot.docs.map((doc) => doc.data());
        if(items){
          setAddress(items[0].address);
          setPhone(items[0].phone);
          setEmail(items[0].email)
        }
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div
      className="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Endereço</h5>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>{address}
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>+55 {phone}
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>{email}
            </p>
            <div className="d-flex pt-2 mb-3">
              <a
                className="btn btn-square btn-outline-light btn-social"
                href="https://instagram.com/unioengenhariaenergy?igshid=NGVhN2U2NjQ0Yg==">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Links Rápidos</h5>
            <a className="btn btn-link" href="#aboutus">
              Sobre Nós
            </a>
            <a className="btn btn-link" href="#contacts">
              Contatos
            </a>
            <a className="btn btn-link" href="/projects">
              Projetos
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a href="/">Unio Engenharia Energy</a>, Todos os direitos reservados.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
