import React, { useState, useEffect, ChangeEvent } from "react";
import {
  db,
  collection,
  getDocs,
  addDoc,
  query,
  deleteDoc,
  doc as firestoreDoc,
  updateDoc,
} from "../../config/firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Document {
  id: string;
  title: string;
  description: string;
  image: string;
}

const TestimonialForm: React.FC = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<string | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);

  const fetchDocuments = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "testimonial"))
      );
      const fetchedDocuments: Document[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Document[];
      setDocuments(fetchedDocuments);
    } catch (error) {
      console.error("Erro ao buscar documentos:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64 = event.target?.result as string;
        setImage(base64);
      };

      reader.readAsDataURL(selectedImage);
    }
  };

  const handleAddDocument = async () => {
    try {
      if (editingId) {
        await updateDoc(firestoreDoc(db, "testimonial", editingId), {
          title: title,
          description: description,
          image: image,
        });
        setEditingId(null);
      } else {
          await addDoc(collection(db, "testimonial"), {
            title: title,
            description: description,
            image: image ?? "",
            timestamp: new Date(),
          });
      }
      setTitle("");
      setDescription("");
      setImage(null);
      setEditingId(null);
      toast.success("Informações atualizadas com sucesso!");
      fetchDocuments();
    } catch (error) {
      toast.error("Erro ao enviar informações. Tente novamente.");
    }
  };

  const handleEditDocument = (id: string) => {
    const documentToEdit = documents.find((doc) => doc.id === id);
    if (documentToEdit) {
      setTitle(documentToEdit.title);
      setDescription(documentToEdit.description);
      setImage(documentToEdit.image);
      setEditingId(id);
    }
  };

  const handleDeleteDocument = async (id: string) => {
    try {
      await deleteDoc(firestoreDoc(db, "testimonial", id));
      toast.success("Documento excluído com sucesso!");
      fetchDocuments();
    } catch (error) {
      console.error("Erro ao excluir documento:", error);
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-12">
          <label className="form-label">Nome</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="">
          <label htmlFor="formFile" className="form-label">
            Imagem
          </label>
          <input
            className="form-control"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Descrição
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control"
            rows={5}></textarea>
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button onClick={handleAddDocument} className="btn btn-primary">
            {editingId ? "Atualizar" : "Salvar"}
          </button>
        </div>
      </div>
      <div className="my-3">
        <h2 className="my-5">Projetos cadastrados</h2>
        <ul className="list-group">
          {documents.map((doc) => (
            <li key={doc.id} className="list-group-item">
              <div className="d-flex align-items-center">
                <div>
                  <strong>{doc.title}</strong>
                  <p>{doc.description}</p>
                </div>
                <img
                  src={doc.image == "" ? "img/clientes.png" : doc.image}
                  alt="Imagem"
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    marginLeft: "auto",
                  }}
                  className="ml-auto"
                />
                <div className="m-2">
                  <div className="mb-1">
                    <button
                      onClick={() => handleEditDocument(doc.id)}
                      className="btn btn-warning btn-sm"
                      style={{ width: "100%" }}>
                      Editar
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => handleDeleteDocument(doc.id)}
                      className="btn btn-danger btn-sm"
                      style={{ width: "100%" }}>
                      Excluir
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TestimonialForm;
