import React from 'react';

const FeaturePoints: React.FC = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5 justify-content-center">
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
            <div className="d-flex align-items-center mb-4">
              <div className="btn-lg-square bg-primary rounded-circle me-3">
                <i className="fa fa-check text-white"></i>
              </div>
            </div>
            <h5 className="mb-3">Projetos Inovadores</h5>
            <span>Explore a excelência em nossos projetos, onde a expertise encontra a criatividade</span>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
            <div className="d-flex align-items-center mb-4">
              <div className="btn-lg-square bg-primary rounded-circle me-3">
                <i className="fa fa-users text-white"></i>
              </div>
            </div>
            <h5 className="mb-3">Clientes Satisfeitos</h5>
            <span>Junte-se aos nossos clientes que confiam em nossos projetos de energia solar.</span>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
            <div className="d-flex align-items-center mb-4">
              <div className="btn-lg-square bg-primary rounded-circle me-3">
                <i className="fa fa-award text-white"></i>
              </div>
            </div>
            <h5 className="mb-3">Equipe Excepcional</h5>
            <span>Nossos membros da equipe são a força motriz por trás do sucesso.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturePoints;
