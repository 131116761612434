import React, { useState, useEffect, ChangeEvent } from "react";
import {
  db,
  collection,
  getDocs,
  doc,
  query,
  limit,
  updateDoc,
} from "../../config/firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FeatureForm: React.FC = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<string | null>(null);
  const [firstDocumentId, setFirstDocumentId] = useState<string | null>(null);

  useEffect(() => {
    const fetchFirstDocumentId = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "feature"), limit(1))
        );
        const firstDocument = querySnapshot.docs[0];
        setFirstDocumentId(firstDocument?.id || null);
        setTitle(firstDocument?.data()?.title || "");
        setDescription(firstDocument?.data()?.description || "");
        setImage(firstDocument?.data()?.description || "");
      } catch (error) {
        console.error("Erro ao buscar o primeiro documento:", error);
      }
    };

    fetchFirstDocumentId();
  }, []);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64 = event.target?.result as string;
        setImage(base64);
      };

      reader.readAsDataURL(selectedImage);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (firstDocumentId) {
        await updateDoc(doc(db, "feature", firstDocumentId), {
          title: title,
          description: description,
          image: image,
          timestamp: new Date(),
        });

        toast.success("Informações atualizadas com sucesso!");
      } else {
        console.error("Nenhum documento encontrado para atualizar.");
      }
    } catch (error) {
      toast.error("Erro ao enviar informações. Tente novamente.");
    }
  };

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-12">
          <label className="form-label">Titulo</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="">
          <label htmlFor="formFile" className="form-label">
            Imagem
          </label>
          <input
            className="form-control"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Descrição
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control"
            rows={5}></textarea>
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button onClick={handleSubmit} className="btn btn-primary">
            Salvar
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default FeatureForm;
