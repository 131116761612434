import React, {useEffect, useState} from "react";
import CarouselForm from "../components/admin/CarouselForm";
import QuoteForm from "../components/admin/QuoteForm";
import AboutForm from "../components/admin/AboutForm";
import TeamForm from "../components/admin/TeamForm";
import ProjectsForm from "../components/admin/ProjectsForm";
import {
  auth,
  signInWithPopup,
  googleAuthProvider,
} from "./../config/firebaseConfig";
import {User} from "firebase/auth";
import FeatureForm from "../components/admin/FeatureForm";
import FooterForm from "../components/admin/FooterForm";
import TestimonialForm from "../components/admin/TestimonialForm";

enum Sections {
  CAROUSEL = "Carousel",
  QUOTE = "Quote",
  FEATURE = "Porque Escolher-nos",
  ABOUT = "About",
  TEAM = "Team",
  TESTIMONIAL = "Depoimentos",
  PROJECTS = "Projects",
  FOOTER = "Rodapé",
}

const Admin: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [selectedSection, setSelectedSection] = useState<Sections>(
    Sections.CAROUSEL
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const allowedEmails = [
    "juliadson.sm@gmail.com",
    "italoleitefeitosa@gmail.com",
    "juarezsoaresneto@gmail.com"
  ];

  const isUserAllowed = user && allowedEmails.includes(user.email || "");

  const handleSectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSection(e.target.value as Sections);
  };

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      console.error("Erro ao fazer login:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  return (
    <div className="container mx-auto p-4 mt-5">
      <div className="col-12">
        {user ? (
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0">Bem-vindo, {user.displayName || user.email}!</p>
            <button className="btn btn-secondary" onClick={handleSignOut}>
              Sair
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0">Realize login com sua conta google!</p>
            <button className="btn btn-primary" onClick={handleSignInWithGoogle}>Login</button>
          </div>
        )}
      </div>
      {isUserAllowed && (
        <>
          <h2 className="my-5">Selecione uma sessão do site para edição</h2>
          <select
            id="sectionSelector"
            value={selectedSection}
            onChange={handleSectionChange}
            className="form-select col-6"
            aria-label="Selecione uma sessão para edição">
            <option value={Sections.CAROUSEL}>Topo</option>
            <option value={Sections.QUOTE}>Contatos</option>
            <option value={Sections.FEATURE}>Porque Escolher-nos</option>
            <option value={Sections.ABOUT}>Sobre Nós</option>
            <option value={Sections.TEAM}>Equipe</option>
            <option value={Sections.PROJECTS}>Projetos</option>
            <option value={Sections.FOOTER}>Rodapé</option>
            <option value={Sections.TESTIMONIAL}>Depoimentos</option>
          </select>

          <hr className="my-4" />
          <div>
            {(() => {
              switch (selectedSection) {
                case Sections.CAROUSEL:
                  return <CarouselForm />;
                case Sections.QUOTE:
                  return <QuoteForm />;
                case Sections.FEATURE:
                  return <FeatureForm />;
                case Sections.ABOUT:
                  return <AboutForm />;
                case Sections.TEAM:
                  return <TeamForm />;
                case Sections.PROJECTS:
                  return <ProjectsForm />;
                case Sections.FOOTER:
                  return <FooterForm />;
                case Sections.TESTIMONIAL:
                  return <TestimonialForm />;
                default:
                  return null;
              }
            })()}
          </div>
        </>
      )}
    </div>
  );
};

export default Admin;
