import React, { useEffect, useState } from 'react';
import { db, collection, getDocs } from '../../config/firebaseConfig';

const Feature: React.FC = () => {
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [image, setImagem] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'feature'));
        const items = querySnapshot.docs.map((doc) => doc.data());
        if(items){
          setDescription(items[0].description);
          setTitle(items[0].title);
          setImagem(items[0].image)
        }
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container feature px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="p-lg-5 ps-lg-0">
              <h6 className="text-primary">Porque Escolher-nos!</h6>
              <h1 className="mb-4">{title}</h1>
              <p className="mb-4 pb-2">{description}</p>
              <div className="row g-4">
                <div className="col-6">
                  <div className="d-flex align-items-center">
                    <div className="btn-lg-square bg-primary rounded-circle">
                      <i className="fa fa-check text-white"></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-0">Qualidade</p>
                      <h5 className="mb-0">Serviços</h5>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center">
                    <div className="btn-lg-square bg-primary rounded-circle">
                      <i className="fa fa-user-check text-white"></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-0">Equipe</p>
                      <h5 className="mb-0">Eficiente</h5>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center">
                    <div className="btn-lg-square bg-primary rounded-circle">
                      <i className="fa fa-drafting-compass text-white"></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-0">Livre</p>
                      <h5 className="mb-0">Consulta</h5>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center">
                    <div className="btn-lg-square bg-primary rounded-circle">
                      <i className="fa fa-headphones text-white"></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-0">Cliente</p>
                      <h5 className="mb-0">Apoia</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '400px' }}>
            <div className="position-relative h-100">
              <img className="position-absolute img-fluid w-100 h-100" src={image} style={{ objectFit: 'cover' }} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
